.container {
    display: flex;
    align-items: center;
}

.container-logos {
    display: flex;
    align-items: center;
    margin-left: 100px;
    gap: 40px;
    width: 100%;
    justify-content: center;
}

@media only screen and (max-width: 1058px) {
    .container {
        flex-flow: column;
    }

    .container-logos {
        justify-content: center;
        margin-left: 0;
        width: 100%;
    }
}

@media only screen and (max-width: 860px) {
    .container {
        flex-flow: column;
    }

    .container-logos {
        flex-flow: column;
        align-items: center;
        margin-left: 0;
        width: 100%;
    }
}