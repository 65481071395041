.container {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 10px;
}

.container-button {
    text-align: center;
}

.text-bold {
    text-align: center;
    font-size: 25px;
    color: #fbbd05;
    font-weight: 800;
}

.nota {
    text-align: center;
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
}