.container {
    margin-top: 50px;
}

.label {
    display: flex;
    align-items: center;
}

.label-right {
    margin-left: 90px
}

.text-right {
    text-align: right;
}

.calendar {
    display: flex;
    align-items: center;
}

.calendar-icon {
    display: flex;
    justify-content: center;
}

.nota_mensaje_error {
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    color: #f44336
}

.a_button {
    cursor: pointer;
    text-decoration: none;
    color: #000;
}