

.centered {
    display: "flex";
    align-items: "center";
    height: "100%";
}


.card-main {
    padding: 100px 265px;
}

.form-component {
    margin-top: 40px;
}

.text-field {
    width: 60%;
}

.text-field .MuiTextField-root {
    width: 100%;
}

.button {
    margin-top: 36px;
    text-align: center;
}

.button .MuiButton-root {
    background-color:#2E3B55;
}

@media only screen and (max-width: 600px) {
    .card-main {
        padding: 100px 50px;
    }

    .text-field {
        width: 100%;
    }
}