.title {
    font-size: 28px;
    font-weight: 800;
    text-align: center;
    position: relative;
    top: 105px;
    color: #fff;
}

.container-cards {
    display: flex;
}

@media only screen and (max-width: 1080px) {
    .container-cards {
        flex-direction: column;
    }
}