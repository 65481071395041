* {
    font-family: 'Open Sans';
}


body {
    margin: 0px;
}

.container-margin {
    margin-left: 250px;
    margin-right: 250px;
}

.margin-top-md {
    margin-top: 80px;
}

.margin-top-sm {
    margin-top: 50px;
}


.react-responsive-modal-modal {
    border-radius: 25px;
    background-color: #f3f3f3;
}

@media only screen and (max-width: 1280px) {
    .container-margin {
        margin-left: 150px;
        margin-right: 150px;
    }
}

@media only screen and (max-width: 860px) {
    .container-margin {
        margin-left: 50px;
        margin-right: 50px;
    }
}