.text {
    text-align: center;
    font-size: 18px;
}

.text-bold {
    font-size: 25px;
    color: #fbbd05;
    font-weight: 800;
}

.container-cards {
    display: flex;
    justify-content: center;
    gap: 15px;
}

@media only screen and (max-width: 1077px) {
    .container-cards {
        flex-direction: column;
        align-items: center;
    }
}
