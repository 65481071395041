.container {
    margin-top: 100px;
}

.title {
    font-size: 21px;
    font-weight: 800;
}

.container-cards {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 35px;
}

@media only screen and (max-width: 480px) {
    .container-cards {
        justify-content: center;
    }
}