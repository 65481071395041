.container {
    display: flex;
    align-items: center;
    width: 480px;
    min-width: 480px;
    height: 320px;
    border-radius: 20px;
    text-align: center;
    background-color: #f3f3f5;
}

.children_text{
    font-size: small;
}

.img {
    width: 25%;
    margin: 0px 10px;
}

.container-text {
    width: 70%;
    text-align: left;
    margin-left: 10px;
    margin-right: 20px;
}

.name {
    font-weight: 800;
}


@media only screen and (max-width: 860px) {
    
    .container {
        flex-direction: column;
        width: 100%;
        min-width: 0;
        height: 410px;
        justify-items: center;
    }

    .img {
        width: 100%;
        margin-top: 20px;
    }


    .container-text {
        text-align: center;
    }
    
    .name {
        text-align: center;
    }

}