.container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 300px;
    height: 420px;
}

.img {
    text-align: center;
}

.img > img {
    width: 90px;
}

.title {
    color: #fff;
    font-weight: 800;
    text-align: center;
}

.text {
    color: #fff;
    margin: 0px 40px; 
    text-align: center;
}