.container {
    position: relative;
}

.container-footer {
    display: flex;
    -webkit-clip-path: polygon(0 0, 100% 25%, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 25%, 100% 100%, 0% 100%);
    flex-direction: column;
    align-items: center;
    color: #fff;
    background-color: #2E3B55;
    font-weight: 300;
    padding: 100px 0px;
    margin-top: 30px;
}

.container-footer_data {
    display: flex;
    align-items: center;
    padding: 50px 150px;
}

.container-footer_data a {
    text-decoration: none;
}

.container-footer_data > div {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: solid 1px;
}

.container-footer_data > div:last-child {
    border-right: none;
}

.container-footer_data > div > p, .container-footer_data > div > span {
    margin: 0px 50px;
}

.container-footer_redes_sociales {
    display: flex;
    margin-bottom: 30px;
    gap: 30px;
}

.container-footer_redes_sociales img {
    width: 50px;
}

.container-button {
    display: flex;
    justify-content: flex-end;
    margin-right: 150px;
    position: relative;
    top: 179px;
    z-index: 100
}

@media only screen and (max-width: 1280px) {
    .container-button{
        margin-right: 60px;
    }

    .container-footer_data {
        padding: 50px 0px;
    }
}

@media only screen and (max-width: 912px) {
    .container-footer_data {
        flex-direction: column;
        justify-items: center;
        padding: 50px 0px;
    }

    .container-footer_data > div { 
        border-right: none;
        border-bottom: solid 1px;
        width: 340px;
        text-align: center;
    }

    .container-footer_derechos {
        text-align: center;
    }

    .container-footer_redes_sociales {
        flex-direction: column;
    }
    
    .container-button {
        position: initial;
        justify-content: center;
        margin-top: 50px;
        margin-right: 0px;
    }
}

/* @media only screen and (max-width: 860px) {
    .container-button {
        margin-right: 30px;
    }
} */