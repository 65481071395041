.button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 30px;
    border: 0;
    width: 200px;
    color: #fff;
    cursor: pointer;
}

.button > span{
    color: #fff;
}