.content-formulario {
  padding: 20px 130px;
}

.head-formulario {
  margin: 118px 150px;
  text-align: center;
  font-size: 30px;
}

.label-styling{
  white-space: break-spaces!important;
}

.label-radio-styling{
  width: 100%;
  font-size: .9rem;
  text-align: center;
  padding: 3px;
}

.smaller-label-radio-styling{
  width: 100%;
  font-size: .8rem;
  text-align: center;
}

.adjust-top-padding{
  padding-top: 100px;
}

.adjust-top-padding.css-rzh4jt-MuiGrid-root>.MuiGrid-item{
  padding-top: 20px;
}

.radio-styling{
  background-color: #f1f1f1;
    width: 100%;
    height: 60px;
    margin: 5px;
    display: flex;
}

.radio-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.adjust-top-padding.css-rzh4jt-MuiGrid-root>.MuiGrid-item.grid-boton {
  padding-top: 86px;
  display: flex;
}

.adjust-top-padding.css-rzh4jt-MuiGrid-root>.MuiGrid-item.grid-boton-2 {
  display: flex;
  font-style: italic;
}


@media only screen and (max-width: 860px) {
  .head-formulario {
    margin: 118px 20px;
  }
}

@media only screen and (max-width: 600px) {
  .content-formulario {
    padding: 20px 20px;
  }
}

.menu-item-form {
    display: flex !important;
    justify-content: left !important;
    padding: 10px 30px !important;
}

.form-cheks {
  margin-top: 10px;
}

.boton-form {
  padding: 10px 10px !important;
  width: 224px !important;
  height: 55px!important;
  border-radius: 30px!important;
}
  

.form-modal {
  width: 600px;
}

.form-modal_img {
  margin: 0px;
  margin-left: 97px;
}

.form-modal_titulo {
  margin: 10px 50px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}

.form-modal_texto {
  margin: 0px 50px;
  margin-bottom: 75px;
  text-align: center;
}

.form-modal_button {
  display: flex;
  justify-content: center;
  background: #011d60;
    color: white;
}

@media only screen and (max-width: 600px) {
  .form-modal {
    width: 300px;
  }
}

.css-rzh4jt-MuiGrid-root>.MuiGrid-item.mid-top-spacing{
  padding-top: 90px;
}

.css-rzh4jt-MuiGrid-root>.MuiGrid-item.lar-top-spacing{
  padding-top: 150px;
}

.text-right {
  text-align: right;
}


.a_button {
  cursor: pointer;
  text-decoration: none;
  color: #000;
}