.container {
    margin-top: 100px;
}

.title {
    font-size: 25px;
    font-weight: 800;
    text-align: center;
}

.mensaje {
    font-size: 18px;
    font-weight: 200;
    text-align: center;
}

.encuestas  {
    display: flex;
    margin-top: 70px;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 25px;
}