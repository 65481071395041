.container {
    margin-top: 100px;
}

.title {
    font-size: 21px;
    font-weight: 800;
}

.link {
    text-decoration: none;
}