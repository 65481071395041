.container-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 186px;
    height: 190px;
}

.header {
    display: flex;
    justify-content: center
}

.header > img {
    width: 100px;
}

.text {
    font-size: 11px;
    padding: 0px 10px;
    text-align: center;
}

.footer {
    width: 186px;
    text-align: center;
}

.complete {
    height: 10px;
    position: relative;
    bottom: 56px;
    text-align: center;
    font-size: 11px;
}

.complete-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}