.container {
    margin-top: 80px;
}


.container-url {
    width: 45%;
}

.container-button {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

@media only screen and (max-width: 860px) {
    .container-url {
        width: 100%;
    }
}