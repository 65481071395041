.container {
    margin-top: 60px;
}

.title {
    font-size: 22px;
    font-weight: 800;
}

.sangria {
    margin-left: 20px;
}