.container {
    margin-top: 60px;
}

.title {
    font-size: 22px;
    font-weight: 800;
}

.container-cards {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 35px;
}