@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap');

.link {
    color: #4285f4;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

.boton-registro {
    background-color: #50a4e0;
    cursor: pointer;
    margin-right: 100px;
}

.button_ovalado{
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-radius: 40px !important;
    background:#2E3B55 !important;
}

@media only screen and (max-width: 1095px) {
    .boton-registro {
      margin-right: 0;
    }
}
