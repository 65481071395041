.container {
    display: flex;
    align-items: center;
    padding: 25px 0px;
}

.container-logos {
    display: flex;
    align-items: center;
    margin-left: 100px;
    gap: 40px;
    width: 50%;
    cursor:pointer;
}

.container-butons {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    margin-right: 100px;
    gap: 20px;
}

.container-user {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-end;
}

.box-info {
    display: flex;
    align-items: center;
}

.box-nombre_usuario {
    display: flex;
    justify-content: flex-end;
    width: 65%;
    padding-right: 20px;
}

.box-cerrar_sesion {
    width: 35%;
    background-color: #011d60;
    color: #fff;
    padding-left: 20px;
    cursor: pointer;
}

@media only screen and (max-width: 1058px) {
    .container {
        flex-flow: column;
        padding: 0px 0px;
        margin-top: 35px;
    }

    .container-logos {
        flex-flow: row;
        justify-content: center;
        margin-left: 0;
        width: 100%;
        gap: 20px;
    }
    .container-butons {
        flex-flow: row;
        margin-right: 0;
        margin-top: 30px;
        justify-content: center;
        width: 100%;
        gap: 0px;
    }
    .box-nombre_usuario {
        width: 50%;
    }
    
    .box-cerrar_sesion {
        width: 50%;
    }
}

@media only screen and (max-width: 860px) {
    .container {
        flex-flow: column;
        padding: 0px 0px;
        margin-top: 35px;
    }

    .container-logos {
        flex-flow: row;
        justify-content: center;
        margin-left: 0;
        width: 100%;
        gap: 20px;
    }

    .container-butons {
        flex-flow: row;
        margin-right: 0;
        margin-top: 30px;
        align-items: center;
        width: 100%;
        gap: 0px;
    }

    .container-butons > button {
       border-radius: 0px;
    }
}

@media only screen and (max-width: 556px) {
    .container {
        padding: 0px !important;
    }
}