.container {
    margin-top: 100px;
    text-align: center;
    background-color: #f3f3f5;
    padding: 35px 0px;
}

.video > iframe{
   width: 500px;
}

@media only screen and (max-width: 860px) {
    .video > iframe{
        width: 350px;
     } 
}