.container-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #f3f3f5;
    width: 186px;
    height: 190px;
    border-radius: 5px;
    text-align: center;
    padding: 0px 10px;
}

.text {
    font-weight: 600;
}

.date {
    padding-bottom: 25px;
}

.footer {
    position: relative;
    bottom: 15px; 
}