.container {
    width: 100%;
    height: 480px;
    background-color: #f3f3f5;
}

.sub-container {
    display: flex;
}

.container-left {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 520px;
}

.container-left > iframe {
    width: 560px;
}

.container-right {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 40%;
    height: 480px;
    background-color: #fbb602;
}

.arrow-down {
    margin-left: 40px;
    margin-bottom: 20px;
}

.title {
    margin-left: 40px;
    font-size: 21px;
    font-weight: 800;
}

.text {
    margin-left: 40px;
    font-size: 16px;
}


@media only screen and (max-width: 1455px) {
    .container-left > iframe {
        width: 460px;
    }
    
}

@media only screen and (max-width: 860px) {
    .container {
        height: auto;
    }
    
    .sub-container {
        flex-flow: column;
    }
    
    .container-left {
        width: 100%;
        height: auto;
    }
    
    .container-right {
        width: 100%;
        height: auto;
        margin-top: 20px;
    }
    
    .arrow-down {
        margin-top: 30px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    
    .title {
        margin-top: 30px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .text {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 487px) {
    .container-left > iframe {
        width: 360px;
    }
}