@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap');

.font-landing * {
    font-family: 'Open Sans', sans-serif !important;
}

.link {
    color: #4285f4;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

.captcha {
    text-align: center;
    margin: 38px 60px;
    color:red;
}

.button_ovalado{
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-radius: 40px !important;
    background:#2E3B55 !important;
}

.link-button {
    cursor: pointer;
    text-decoration: underline;
    border: none;
    background: none;
    margin-top: 10px;
}

.link-button {
  cursor: pointer;
  text-decoration: underline;
  border: none;
  background: none;
  margin-top: 10px;
}

.button-link {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: black;
    text-decoration: underline;
    cursor: pointer;
}
  

@media only screen and (max-width: 600px) {
    .captcha {
        text-align: center;
        margin: 38px -15px;
        color:red;
    }

    .captcha > div > div > div {
        width: 277px;
    }
}