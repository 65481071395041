.container {
    width: 320px;
    height: 510px;;
    min-width: 320px;
    border-radius: 20px;
    border: solid 1px #d6d6d6;
    text-align: center;
}

.container-img {
    margin-top: 15px;
}

.container-text {
    margin: 0px 30px;
}

.title {
   font-size: 21px; 
}

.text {
    font-size: 16px; 
}

.nota {
    font-style: italic;
    font-size: 12px; 
}