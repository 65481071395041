.container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 700px;
    /* background-image: url("https://img.freepik.com/vector-premium/concepto-computacion-nube_43778-430.jpg?w=1480"); */
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
}

.text {
    width: 50%;
    margin: 0px 250px;
    font-size: 40px;
}

.text span, .text b {
    color: #fff;
}


@media only screen and (max-width: 860px) {
    .text {
        width: 100%;
    }

    .container {
        height: 870px;
    }
}

@media only screen and (max-width: 556px) {
    .container {
        height: 573px;
        background-size: 124%;
        background-position: -28px 4px;
    }
}

@media only screen and (min-width: 1920px) {
    .container {
        height: 900px;
    }
}