.title {
    font-size: 21px;
    font-weight: 800;
}

.container-cards {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
}

@media only screen and (max-width: 860px) {

    .title {
        text-align: center;
    }

    .container-cards {
        flex-direction: column;
    }

}