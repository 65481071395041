.container-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    width: 186px;
    height: 190px;
    border-radius: 5px;
    padding: 0px 12px;
    text-align: center;
}

.text {
    font-weight: 600;
}

.date {
    padding-bottom: 25px;
}

.footer > div > a {
    color:#000000;
}