.container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    text-align: center;
    font-size: 22px;
}

.container-progress_bar {
    width: 50%;
    margin-top: 20px;
}

.porcent-text {
    width: 50%;
}

.porcent-text-move {
    text-align: end;
}