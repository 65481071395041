/* Seccion de las preguntas frecuentes */
.acordion-root .MuiAccordion-root:before {
    height: 0px;
}

.acordion-container {
    background-color: #f7f7f7 !important;
    margin-bottom: 5px !important;
    box-shadow:none !important;
}

.acordion-item-ask {
    padding: 0px 250px !important;
    min-height: 59px !important;
}

.acordion-item-detail {
    width: 80%;
    justify-content: start; 
}

.acordion-item-detail .MuiTypography-root {
    margin: 0px 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.check-acuerdo {
    margin-top: 15px;
}

.check-acuerdo .MuiTypography-root{
    margin: 0px 0px;
}

.alert-acordion {
    width: 100%;
}

.acordion-item-ask .MuiIconButton-root {
    background-color: #50a4e0;
    color: #fff !important;
    width: 10px;
    height: 10px;
}

.acordion-item-ask .MuiIconButton-root:hover {
    background-color: #167cc5;
}

@media only screen and (max-width: 1280px) {
    .acordion-item-ask {
        padding: 0px 150px !important;
    }

}

@media only screen and (max-width: 860px) {
    .acordion-item-ask {
        padding: 0px 50px !important;
    }

    .acordion-item-detail .MuiTypography-root {
        margin: 0px 20px;
    }
}
